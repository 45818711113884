(function($) {
    if ($(window).width() < 1023) {
    $(document).ready(function() {
        // Initialize the bannerslider
        var $slick = $('.bannerslider');
        
        if ($slick.length) { // Check if the element exists
            $slick.slick({
                dots: false,
					infinite: true,
					speed: 500,
					autoplay: true,
					autoplaySpeed: 4000,
					fade: true,
					cssEase: 'linear',
					slidesToShow: 1,
					initialSlide: 1,
					arrows: true,
				});
        } else {
            console.error('Bannerslider element not found');
        }
    });
}
})(jQuery);